<template>
  <div class="about">
<!--    <header-nav :title="headerTitle" :goBack="false" />-->
    <div class="pay_container">
      <div class="logo_title">
        <img class="logo" src="@/assets/images/public/logo.png"/>
        <div class="title">{{ name }}</div>
      </div>

      <div class="input_container">
        <div class="input_outer">
          <div class="input_text">消费金额</div>
          <div class="input_text input_money">¥{{amount}}</div>
        </div>
      </div>

      <div class="pay_bottom_keyboard">
        <div class="keyboard_container">
          <div class="keyboard_left">
            <div class="items">
              <div v-for="(item, index) in keyboardLeft" :key="index" class="item_outer">
                <div @click="handleInput(item)" class="item_inner item_inner_whm" >{{item}}</div>
              </div>
            </div>
          </div>
          <div class="keyboard_right">
            <div class="C item_outer">
              <div @click="handleClear" class="item_inner item_inner_whm pay_clear">清空</div>
              <div class="pay_del_whm">
                <div @click="handleInput('⌫')" data-value="⌫" class="item_inner item_inner_whm pay_del">⌫</div>
              </div>
              <div class="sure_pay_container">
                <div @click="handlePay" class="CCC item_inner sure_pay">
                  <text class="pay_text">确认</text>
                  <text class="pay_text">支付</text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import HeaderNav from '@/components/header/HeaderNav'
import AliPayMixin from '@/views/mixins/AliPay'
import { getUrlParam, getLastSegment, isAlipayOrWechat } from '@/utils'
// import Home from '@/api/api/home'
import { mapGetters } from 'vuex'

export default {
  name: 'Pay',
  mixins: [AliPayMixin],
  /* computed: {
    ...mapGetters(['merchId', 'businessName'])
  }, */
  components: {
    // HeaderNav
  },
  data () {
    return {
      headerTitle: 'xx',
      amount: '0',
      keyboardLeft: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '00', '0', '.'],
      payMoney: 0,
      vaildCodeShow: false,
      selectCoupon: {},

      hbUseStatus: false,
      selectHb: {},

      merchId: '',
      userAgent: '',
      out_trade_no: '',

      name: ''
    }
  },
  created () {
    this.userAgent = isAlipayOrWechat()
    this.merchId = getLastSegment(window.location.href) // getUrlParam('merch')
  },
  mounted () {
    this.queryMerchInfo(this.merchId)
  },
  methods: {
    /** 获取商户信息 */
    queryMerchInfo (merchId) {
      if (merchId) {
        this.$http.Home.postMerchInfo(merchId).then(res => {
          if (res.shortName || res.name) {
            this.name = res.shortName || res.name || '阅律科技'
          }
        }, () => {
          this.$toast.fail('获取参数失败')
        }).catch(() => {
          this.name = '阅律科技'
          this.$toast.fail('获取参数失败')
        }).finally(() => {
        })
      } else {
        this.$toast({
          message: '商户号获取失败',
          icon: 'fail',
          mask: true
        })
      }
    },

    handleUrl () {
      console.log(window.AlipayJSBridge)

      // console.log(getUrlParam(window.location.href))
    },

    /** 震动 */
    handleVibrate () {
      AlipayJSBridge.call('vibrate')
    },

    handleInput (e) {
      /*if (window.AlipayJSBridge) {
        this.handleVibrate()
      } else {
        document.addEventListener('AlipayJSBridgeReady', this.handleVibrate, false)
      }*/

      const value = e
      const amount = this.amount

      // 避免首位为 '00'
      if ((amount === '0' && value === '0') || (amount === '0' && value === '00')) {
        return
      }

      // 处理删除键
      if (value === '⌫') {
        this.amount = amount.length > 1 ? amount.slice(0, -1) : '0'
        return
      }

      // 避免多余的小数位
      if (amount.includes('.') && amount.split('.')[1].length >= 2 && value !== '⌫') {
        return
      }

      // 避免连续输入 '.'
      if (value === '.' && amount.includes('.')) {
        return
      }

      // 如果第一位是 0，允许输入 '.'
      if (amount === '0' && value !== '.') {
        this.amount = value
        return
      }

      // 计算新的金额值
      const newAmount = amount + value

      if (parseFloat(newAmount) > 10000000) {
        this.$toast({
          message: '金额不能超过1000000.00',
          icon: 'fail',
          mask: true
        })
        return
      }

      this.amount = newAmount
    },

    /** 清空金额 */
    handleClear () {
      // wx.vibrateShort();
      this.amount = '0'
    },

    /** 确认支付 */
    handlePay () {
      const amount = this.amount
      const couponValue = this.selectCoupon.value || 0
      const value = this.selectHb.value || 0

      let payMoney = 0
      let usehb = false

      if (amount && Number(amount) > 0) {
        if (this.hbUseStatus) {
          if ((Number(value) - Number(amount)) >= 0) {
            usehb = true
            payMoney = 0
          } else {
            payMoney = Number(amount) - Number(value) - Number(couponValue)
          }
        } else {
          if ((Number(couponValue) - Number(amount)) >= 0) {
            payMoney = 0
          } else {
            payMoney = Number(amount) - Number(couponValue) - Number(value)
          }
        }

        this.payMoney = payMoney
        this.vaildCodeShow = true
      } else {
        this.$toast('请输入金额')
        return
      }

      this.handleOrderCreate()

      return

      if (window.AlipayJSBridge) {
        this.alPay()
      } else {
        document.addEventListener('AlipayJSBridgeReady', this.alPay, false)
      }
    },

    /** 创建订单 */
    handleOrderCreate () {
      const params = { merchantId: this.merchId, price: this.payMoney, couponId: '', redpacket: 0 }

      // this.$toast.loading('订单创建中...')
      this.$http.order.create(params).then(res => {
        this.queryPayParams(res)
      }, () => {
        this.$toast.fail('获取参数失败')
      }).catch((err) => {
        console.log(err)
      })
    },

    /** 查询支付参数 */
    queryPayParams (data) {
      let payType = '3'
      if (this.userAgent === 'alipay') {
        payType = 3
      } else if (this.userAgent === 'unionPay') {
        payType = 1
      } else if (this.userAgent === 'wechat') {
        payType = 2
      }

      const params = {
        userId: '',
        merchantId: data.merchantId,
        orderId: data.id,
        orderDesc: '收款码',
        amount: data.price,
        payType,
        tradeType: 1,
        buyerLogonId: '', // 支付宝
        buyerId: '', // 支付宝
        authId: '' // 云闪付
      }
      this.$toast.loading('支付加载中...')
      this.$http.pay.pay(params).then(res => {
        const resData = JSON.parse(res)

        if (resData.code === '0000' && resData.data && resData.data.qr_code) {
          this.out_trade_no = resData.data.out_trade_no
          // this.handleAliPay()
          // return
          window.location.href = resData.data.qr_code
        } else if (resData.code === '0002') {
          this.$toast(resData.msg)
        } else {
          this.$toast(resData.msg)
        }
      }, (err) => {
        console.log(err)
      }).catch(() => {
        this.$toast.fail('获取参数失败')
      }).finally(() => {
        // this.$toast.clear()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
//const itemW = (750 - 70) / 4
//const itemH = itemW * 0.56
//const itemM = 7         //:style="{itemW + 'px', height: {{itemH * 2 + 14 * pxToPx}}px; margin: {{itemM}}px;"

@import "../../styles/mixin";
.about {
  position: fixed;
  z-index: 1002;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-width: 750px;
  margin: 0 auto;
  background: #fff;

  .pay_container {
    flex: 1;
    overflow-y: hidden;
    .logo_title {
      @include CCFS;
      padding-top: 58px;
      .logo {
        width: 112px;
        height: 112px;
        border-radius: 8px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
      }
      .title {
        color: rgb(51, 51, 51);
        font-size: 32px;
        font-weight: 400;
        line-height: 48px;
        text-align: center;
        margin-top: 20px;
      }
    }
  }

  .input_container {
    padding: 0 32px;
    margin-top: 50px;
    .input_outer {
      @include RCSB;
      height: 108px;
      background: #f8f8f8;
      padding: 0 40px;
      border-radius: 8px;
      .input_text {
        font-size: 32px;
        color: #000;
        white-space: nowrap;
      }
      .input_money {
        font-size: 38px;
        font-weight: bold;
      }
    }
    .hb_switch {
      flex: 1;
      padding: 0 20px;
    }
  }

  .pay_bottom_keyboard {
    position: fixed;
    bottom: 0;
    z-index: 99;
    max-width: 750px;
    .keyboard_container {
      @include R;
      background-color: #f2f3f5;
      padding: 7px;
      .keyboard_left {
        .items {
          @include RJFSW;
        }
      }
      .item_inner_whm {
        width: 170px;
        height: 102px;
        margin: 7px;
      }
      .item_inner {
        touch-action: none; /* 这将禁用所有默认触摸操作 */
        @include RCC;
        background-color: #fff;
        border-radius: 20px;
        font-size: 44px;
        color: #323233;
        font-weight: bold;
        font-family: 'Times New Roman', Times, serif;
      }
      .item_inner:active {
        background: $pageColor;
      }
      .pay_del_whm {
        overflow: hidden;
      }
      .sure_pay_container {
        overflow: hidden;
      }
      .sure_pay {
        background: #08a128;
        width: 170px;
        height: 218px;
        margin: 7px;
      }
      .sure_pay:active {
        background-color: #138d2d;
      }
      .pay_clear {
        font-size: 34px;
        color: #323233;
        font-weight: normal;
      }
      .pay_text {
        font-size: 34px;
        color: #fff;
        font-weight: normal;
        line-height: 58px;
      }
    }
  }
}
</style>
